<template>
  <div id="form-kd">
    <a-form-model :model="dataPayload" ref="ruleForm" :rules="rules">
      <a-row :gutter="16">
        <a-col :md="24" :lg="12">
          <a-form-model-item :colon="false">
            <span slot="label"> Subject&nbsp;</span>
            <a-select
              size="large"
              class="w-100"
              v-model="idMapel"
              disabled
            >
              <a-select-option
                v-for="item in subjects"
                :key="item.id"
                :value="item.id"
              >
                {{ item.nama }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="24" :lg="12">
          <a-form-model-item :colon="false">
            <span slot="label"> Level&nbsp;</span>
            <a-select
              size="large"
              class="w-100"
              v-model="idLevel"
              disabled
            >
              <a-select-option
                v-for="item in levels"
                :key="item.id"
                :value="item.id"
              >
                {{ item.nama }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="24" :lg="24">
          <a-form-model-item :colon="false">
            <span slot="label"> Template Type&nbsp;</span>
            <a-select
              size="large"
              class="w-100"
              :value="type"
              disabled
            >
              <a-select-option
                v-for="item in types"
                :key="item.key"
                :value="item.key"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="24" :lg="12">
          <a-form-model-item :colon="false" prop="id_predikat">
            <span slot="label"> Predicate&nbsp;</span>
            <a-select
              size="large"
              :value="dataPayload.id_predikat"
              @change="(e) => handleChange(e, 'id_predikat')"
            >
              <a-select-option
                v-for="item in predicates"
                :key="item.id"
                :value="item.id"
              >
                {{ item.nama }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="24" :lg="12">
          <a-form-model-item :colon="false">
            <span slot="label"> Alias&nbsp;</span>
            <a-select
              size="large"
              :value="dataPayload.id_predikat"
              disabled
            >
              <a-select-option
                v-for="item in predicates"
                :key="item.id"
                :value="item.id"
              >
                {{ item.alias }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="24" :lg="24">
        <a-form-model-item label="Description" prop="description">
          <a-textarea
            v-model="dataPayload.description"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-model-item>
      </a-col>
      <a-col :md="24" :lg="24">
        <a-form-item label="Variables">
          <div class="d-flex">
            <div
              v-for="item in config.templateVariables"
              :key="item.name"
              class="mr-3"
            >
              <a-button
                class="border-primary text-primary"
                @click.prevent="handleChange(item.code, 'variable')"
                size="large"
              >
                {{ item.name }}
              </a-button>
            </div>
          </div>
        </a-form-item>
      </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import config from '@/config'

export default {
  data() {
    return {
      predicates: [],
      config,
    }
  },
  props: {
    dataPayload: {
      type: Object,
      required: true,
    },
    loadingValidate: {
      type: Boolean,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
    },
    levels: {
      type: Array,
      required: true,
    },
    idMapel: {
      type: Number,
    },
    types: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
    },
    idLevel: {
      type: Number,
    },
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
    async fetchPredicate() {
      try {
        const data = await this.$store.dispatch('master/FETCH_PREDICATE', { page: 'all' })
        this.predicates = data
      } catch (err) {
        console.log(err)
        return this.$notification.error({
          message: 'Error',
          description: 'The process of fetching predicate encountered problems. Please try again later',
        })
      }
    },
  },
  computed: {
    rules() {
      return {
        id_predikat: [
          { required: true, message: 'Predicate is required!', trigger: 'change' },
        ],
        type: [
          { required: true, message: 'Template type is required!', trigger: 'change' },
        ],
        description: [
          { required: true, message: 'Description is required!', trigger: 'change' },
        ],
      }
    },
  },
  watch: {
    loadingValidate(newVal) {
      if (newVal) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit('validationForm', true)
          } else {
            this.$emit('validationForm', false)
            // this.$notification.error({
            //   message: 'Maaf',
            //   description: 'Ada dataPayload wajib yang belum terisi/sesuai. Periksa kembali.',
            // })
            // return false
          }
        })
      }
    },
  },
  mounted() {
    this.fetchPredicate()
  },
}
</script>

<style>
</style>
